import * as React from "react";
const SvgQuality = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 256 256", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("defs", null), /* @__PURE__ */ React.createElement("g", { style: {
  stroke: "none",
  strokeWidth: 0,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "none",
  fillRule: "nonzero",
  opacity: 1
}, transform: "translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" }, /* @__PURE__ */ React.createElement("path", { d: "M 45 89.998 c -0.867 0 -1.734 -0.137 -2.559 -0.41 c -19.586 -6.506 -32.241 -22.036 -32.241 -39.566 V 18.234 c 0 -2.718 1.936 -5.043 4.603 -5.529 l 0 0 c 10.332 -1.879 19.341 -5.76 26.775 -11.534 c 2.014 -1.564 4.828 -1.564 6.843 0 c 7.435 5.774 16.442 9.655 26.775 11.534 c 2.666 0.485 4.602 2.811 4.602 5.529 v 31.788 c 0 17.53 -12.654 33.061 -32.238 39.566 C 46.734 89.861 45.867 89.998 45 89.998 z M 15.519 16.641 c -0.764 0.139 -1.318 0.809 -1.318 1.593 v 31.788 c 0 15.776 11.58 29.817 29.5 35.77 c 0.837 0.279 1.76 0.277 2.6 0 c 17.919 -5.952 29.498 -19.993 29.498 -35.77 V 18.234 c 0 -0.784 -0.555 -1.455 -1.318 -1.593 c -10.972 -1.996 -20.564 -6.138 -28.512 -12.31 c -0.57 -0.441 -1.366 -0.442 -1.937 0 C 36.084 10.503 26.491 14.645 15.519 16.641 L 15.519 16.641 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 43.173 55.792 c -1.007 0 -2.013 -0.383 -2.779 -1.149 l -7.451 -7.451 c -0.743 -0.742 -1.152 -1.729 -1.152 -2.779 s 0.409 -2.037 1.152 -2.78 l 2.198 -2.198 c 1.532 -1.532 4.026 -1.532 5.559 0 l 2.474 2.473 l 6.126 -6.126 c 1.484 -1.485 4.074 -1.485 5.559 0 l 2.199 2.198 v 0 c 0.742 0.742 1.151 1.729 1.151 2.779 s -0.409 2.037 -1.151 2.779 L 45.953 54.643 C 45.187 55.409 44.18 55.792 43.173 55.792 z M 37.872 42.262 l -2.101 2.198 l 7.451 7.354 l 10.958 -11.056 l -2.15 -2.149 l -7.443 7.54 c -0.78 0.781 -2.048 0.781 -2.828 0 L 37.872 42.262 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M 45 66.057 c -12.15 0 -22.034 -9.884 -22.034 -22.034 S 32.85 21.989 45 21.989 c 12.149 0 22.034 9.884 22.034 22.034 S 57.149 66.057 45 66.057 z M 45 25.989 c -9.944 0 -18.034 8.09 -18.034 18.034 S 35.056 62.057 45 62.057 c 9.944 0 18.034 -8.09 18.034 -18.034 S 54.944 25.989 45 25.989 z", style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 10,
  fill: "currentColor",
  fillRule: "nonzero",
  opacity: 1
}, transform: " matrix(1 0 0 1 0 0) ", strokeLinecap: "round" })));
export default SvgQuality;
