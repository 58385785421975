import * as React from "react";
const SvgAngular = (props) => /* @__PURE__ */ React.createElement("svg", { style: {
  enableBackground: "new 0 0 512 512"
}, viewBox: "0 0 512 512", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", ...props }, /* @__PURE__ */ React.createElement("g", { id: "comp_x5F_21-angular" }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { points: "    218.712,270 293.286,270 256,176.909 218.712,270   ", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 20,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("polygon", { points: "    256,36.78 52.635,109.593 83.756,379.407 256,475.22 428.242,379.407 459.365,109.593   ", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 20,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("polygon", { points: "    390,360 335.856,360 310.217,305.994 201.978,305.994 176.335,360 120,360 256,83.936   ", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 20,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10
} }))), /* @__PURE__ */ React.createElement("g", { id: "Layer_1" }));
export default SvgAngular;
